<template>
  <div
    ref="container"
    :class="classArray"
    class="rds-video videohtml5">
    <img
      v-if="posterImage && !fullscreen"
      :src="posterImage"
      class="poster-image"
    />
    <div
      v-if="loading && fullscreen"
      class="loading">
      <img src="../assets/img/loading.gif" />
    </div>
  </div>
</template>

<script>
import { eventBus, EVENTS } from '../services/EventBus';
import VideoPlayer from '../services/Videoplayer.rds';

let vplayer = null;
let timeout = null;
let changeUrlTimeout = null;

function onChangeState() {
  console.log('emit leaveFullScreen');
  eventBus.emit(EVENTS.HIDE_VIDEO);
}

function fullscreenChange(self, val) {
  // fullscreen change
  if (val) {
    // new VideoPlayer - fullscreen
    if (!vplayer || !vplayer.player) {
      // new VideoPlayer - watch
      const playerConfig = {
        src: self.src,
        videoType: '',
        container: self.$refs.container
      };
      vplayer = new VideoPlayer(playerConfig);
    }
    if (vplayer.player.paused) {
      vplayer.play();
    }
    vplayer.addListener('ended', onChangeState.bind(self));
    document.addEventListener('keydown', self.onKeyDown());
  } else {
    document.removeEventListener('keydown', self.onKeyDown());
  }
}

export default {
  name: 'RdsVideo',
  props: ['src', 'type', 'fullscreen', 'width', 'heigth', 'posterImage'],
  data() {
    return {
      ffw: false,
      rwd: false,
      loading: true
    };
  },
  computed: {
    classArray() {
      const classes = ['video'];
      if (this.fullscreen) {
        classes.push('fullscreen-video');
        if (this.ffw) {
          classes.push('seeking-fwd');
        } else if (this.rwd) {
          classes.push('seeking-rwd');
        }
      }
      return classes;
    }
  },
  watch: {
    fullscreen(newVal) {
      var self = this;
      fullscreenChange(self, newVal);
    }
  },
  mounted() {
    eventBus.on(EVENTS.KEY_DOWN, this.onKeyDown.bind(this), 'fullpageKeyDown');
    if (timeout) {
      // cancello timeout mounted
      clearTimeout(timeout);
      timeout = null;
    }
    if (changeUrlTimeout) {
      // cancello changeUrlTimeout mounted
      clearTimeout(changeUrlTimeout);
      changeUrlTimeout = null;
    }
    // debugger;
    this.startFn();
    // timeout = setTimeout(this.startFn(), 750);
  },
  beforeDestroy() {
    try {
      if (timeout) {
        // cancello timeout beforeDestroy
        clearTimeout(timeout);
        timeout = null;
      }
      if (vplayer && vplayer.player) {
        // beforeDestroy - stop
        // vplayer.stop();
      }
      vplayer = null;
      // beforeDestroy
      document.removeEventListener('keydown', this.onKeyDown());
    } catch (error) {
      console.warn(error);
    }
  },
  methods: {
    onKeyDown(e) {
      const key = e;
      if (this.fullscreen) {
        if (key === 'ArrowLeft' || key === window.KeyEvent.VK_LEFT) {
          vplayer.seek(-5);
          this.rwd = true;
          vplayer.player.classList.add('seeking-rwd');
          setTimeout(() => {
            this.rwd = false;
          }, 500);
        } else if (key === 'ArrowRight' || key === window.KeyEvent.VK_RIGHT) {
          vplayer.seek(5);
          this.ffw = true;
          setTimeout(() => {
            this.ffw = false;
          }, 500);
        } else if (key === 'Enter' || key === window.KeyEvent.VK_ENTER) {
          if (!vplayer.player.paused) {
            vplayer.pause();
          } else {
            vplayer.play();
          }
        }
      }
    },
    startFn() {
      // new VideoPlayer - mounted
      const playerConfig = {
        src: this.src,
        videoType: this.type,
        container: this.$refs.container
      };
      vplayer = new VideoPlayer(playerConfig);
      vplayer.setURL(this.src);
      vplayer.play();
      vplayer.addListener('ended', onChangeState.bind(this));
      vplayer.addListener('loadstart', () => {
        // loadstart
        this.loading = true;
      });
      vplayer.addListener('canplay', () => {
        // canplay
        this.loading = false;
      });
      vplayer.addListener('error', () => {
        // error
        this.startFn();
      });
      vplayer.addListener('ended', onChangeState.bind(this));
      vplayer.play();
    }
  }
};
</script>

<style scoped lang="scss">
.rds-video {
  position: relative;
}
.rds-video img {
  position: absolute;
  width: 100%;
  height: auto;
  z-index: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 4%);
}
.rds-video.videooipf img {
  z-index: 0;
}
.rds-video /deep/ video {
  width: 100%;
  height: auto;
}
.rds-video /deep/ object {
  width: 100%;
  height: auto;
  z-index: -1;
  position: relative;
}
.rds-video.loading /deep/ object {
  display: none;
}
.rds-video.fullscreen-video.loading /deep/ object {
  display: block;
}

.fullscreen-video {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 10;
  /deep/ object {
    position: absolute !important;
    left: 0px;
    top: 0px;
    width: 1280px !important;
    height: 720px !important;
    z-index: 10;
  }
  &::after {
    content: '';
    position: absolute;
    z-index: 3;
    width: 150px;
    height: 150px;
    top: calc(50% - 75px);
    left: calc(50% - 75px);
    transform: translate(450%, 250%);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0;
    transition: opacity 0.4s;
  }
  &.stalled:not(.seeking-rwd)::after,
  &.stalled:not(.seeking-fwd)::after,
  &.stalled:not(.play)::after,
  &.stalled:not(.pause)::after {
    opacity: 1;
    transform: rotate(0deg);
    animation: spin 2.2s linear infinite;
    background-image: url('../assets/img/player/loading-trasp.png');
  }
  &.seeking-rwd::after {
    opacity: 1;
    background-image: url('../assets/img/player/seek30_rwd-nb.png');
  }
  &.seeking-fwd::after {
    opacity: 1;
    background-image: url('../assets/img/player/seek30_fwd-nb.png');
  }
  &.play::after {
    opacity: 1;
    background-image: url('../assets/img/player/play.svg');
  }
  &.pause::after {
    opacity: 1;
    background-image: url('../assets/img/player/pause.svg');
  }
}
.dark .fullscreen-video {
  &.play::after {
    background-image: url('../assets/img/player/play_d.svg');
  }
  &.pause::after {
    background-image: url('../assets/img/player/pause_d.svg');
  }
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.videohtml5 /deep/ video {
  width: 100%;
  height: auto;
  position: absolute;
}
.videohtml5.fullscreen-video {
  height: auto;
  width: auto;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 10;
  & /deep/ video {
    left: 0px;
    top: 0px;
    width: 1280px;
    height: 720px;
  }
}
.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgb(255, 255, 255);
  opacity: 0.5;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
  }
}
</style>

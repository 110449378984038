import { render, staticRenderFns } from "./MenuItemCityChoice.vue?vue&type=template&id=5fc7bede&scoped=true&"
import script from "./MenuItemCityChoice.vue?vue&type=script&lang=js&"
export * from "./MenuItemCityChoice.vue?vue&type=script&lang=js&"
import style0 from "./MenuItemCityChoice.vue?vue&type=style&index=0&id=5fc7bede&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fc7bede",
  null
  
)

export default component.exports
<template>
  <div v-show="store.isPlayingVideo">
    <div>
      <RdsVideo
        v-if="store.isPlayingVideo"
        :src="videoclipUrl"
        :type="videoType"
        :fullscreen="true"
      />
    </div>
    <div id="iv">
      <div id="infovideoclip">
        {{ title }}{{ subtitle ? ',' : '' }} {{ subtitle }}
      </div>
      <img
        id="rdslogo"
        src="https://media.rds.it/web/web-site/footer/logo-footer.png"
      />
    </div>
  </div>
</template>
<script>
import { eventBus, EVENTS } from '../services/EventBus';
import store from '../Store';
import RdsVideo from './RdsVideo.new.vue';
export default {
  name: 'Videoclip',
  components: {
    RdsVideo,
  },
  data() {
    return {
      title: '',
      subtitle: '',
      store: store,
      videoclipUrl: '',
      videoType: 'application/dash+xml',
      fullscreen: true,
    };
  },
  methods: {
    closeVideoClip() {
      // mainVideo.reset();
      if (!store.fullpage) {
        window.location.reload();
      }
      store.isPlayingVideo = false;
    },
  },
  mounted() {
    const vm = this;
    eventBus.on(EVENTS.HIDE_VIDEO, () => {
      this.closeVideoClip();
    });
    eventBus.on(EVENTS.SHOW_VIDEO, (e) => {
      console.log('EVENTS.SHOW_VIDEO', EVENTS.SHOW_VIDEO);
      if (store.isPlayingVideo && e.videoclipUrl == this.videoclipUrl) {
        return;
      }
      vm.title = e.title;
      vm.subtitle = e.subtitle;
      vm.videoclipUrl = e.videoclipUrl;
      if (e.videoType) {
        vm.videoType = e.videoType;
      } else {
        vm.videoType = 'application/dash+xml';
      }
      store.isPlayingVideo = true;
    });
  },
};
</script>
<style lang="scss" scoped>
#rdslogo {
  display: block;
  position: fixed;
  bottom: 60px;
  left: 60px;
  width: 100px;
  z-index: 9999;
}

#infovideoclip {
  background-color: rgb(9, 81, 161);
  position: fixed;
  left: 148px;
  bottom: 60px;
  /* width: 300px; */
  height: 35px;
  line-height: 35px;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 9999;
}
</style>
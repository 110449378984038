<template>
  <div class="bottom-area">
    <transition name="slide-up">
      <div v-if="currentMenu" id="bar-down" class="bar-down">
        <template v-for="item in currentMenu">
          <MenuItem
            :id="item.id"
            :item="item"
            :key="item.color"
            :class="{ pressed: item.pressed }"
            v-if="!item.type && currentMenuName != 'MENU_CITTA_SCEGLI'"
          />
          <!-- <div :key="item.color">{{item}}</div> -->
          <MenuItemCityChoice
            :choiching="choiching"
            :item="item"
            :key="item.color"
            v-if="item.type"
          />
        </template>
        <div
          v-if="!currentMenuName || currentMenuName === 'MAIN_MENU'"
          class="bar-down-box cookie"
        >
          <div class="bar-down-box-button">
            <IconUp />
          </div>
          <span>Cookie Policy</span>
        </div>
      </div>
    </transition>
    <NewsPreview
      class="news-preview"
      v-if="!currentMenu && !store.widget && showNewsPreview"
      :currentArticle="currentArticle"
    />
  </div>
</template>
<script>
const TITLE_DURATION_SEC = 50;
const TITLE_ROTATION_SEC = 10;
const TITLES_INTERVAL_SEC = 5 * 60;
const MENU_DURATION = 10000;

import { eventBus, EVENTS } from '../services/EventBus';
import store from '../Store';
import MenuItem from './MenuItem';
import MenuItemCityChoice from './MenuItemCityChoice';
import NewsPreview from './NewsPreview';
import IconUp from './IconUp';
export default {
  name: 'MenuBar',
  components: {
    MenuItem,
    MenuItemCityChoice,
    NewsPreview,
    IconUp
  },
  data() {
    return {
      currentMenu: MENUS['MAIN_MENU'],
      currentMenuName: 'MAIN_MENU',
      store: store,
      lastOperation: new Date(),
      lastClosure: false,
      lastShowNewsPreview: false,
      choiching: false,
      showNewsPreview: false,
      currentArticleIdx: 0
    };
  },
  computed: {
    currentArticle() {
      return store.news[this.currentArticleIdx];
    }
  },
  methods: {
    incrementArticleIdx() {
      if (
        store.news &&
        store.news.length &&
        this.currentArticleIdx < store.news.length - 1
      ) {
        this.currentArticleIdx++;
      } else {
        this.currentArticleIdx = 0;
      }
    }
  },
  mounted() {
    const vm = this;
    eventBus.on(EVENTS.SET_CITY, city => {
      localStorage.setItem('city', JSON.stringify(city));
      // store.currentCity = city;
      store.setCity(city);
      if (store.news && store.news.length) {
        this.currentArticleIdx = 0;
      }
      MENUS['MENU_CITTA'] = [
        {
          color: 'red',
          label: store.currentCity
            ? store.currentCity.name
            : 'Seleziona la città',
          event: EVENTS.OPEN_MENU,
          key: 'VK_RED',
          eventParams: 'MENU_CITTA_SCEGLI',
          enabled: () => true
        },
        {
          color: 'green',
          key: 'VK_GREEN',
          label: 'Meteo',
          event: EVENTS.SHOW_RIGHT_BLOCK,
          eventParams: { widget: 'meteo', color: 'green' },
          enabled: store => store.currentCity.hasMeteo
        },
        {
          color: 'yellow',
          label: 'News',
          key: 'VK_YELLOW',
          event: EVENTS.SHOW_RIGHT_BLOCK,
          eventParams: { widget: 'news', color: 'yellow' },
          enabled: store => store.currentCity.hasNews
        }
      ];
      MENUS['MAIN_MENU'][1].label = city.name;
      // MENUS['MENU_CITTA'][0].label = city.name;
      this.currentMenu = MENUS['MENU_CITTA'];
      this.currentMenuName = 'MENU_CITTA';
      this.choiching = false;
    });
    eventBus.on(EVENTS.KEY_DOWN, key => {
      this.lastOperation = new Date();
      this.showNewsPreview = false;
      // const videoclipUrl = store.videoclipUrl;
      // if (key == window.KeyEvent.VK_BLUE && store.isPlayingVideo) {
      //   eventBus.emit(EVENTS.SHOW_VIDEO, {
      //     videoclipUrl: videoclipUrl
      //   });
      //   return;
      // }
      if (!this.currentMenu && store.fullpage) {
        this.currentMenu = MENUS['MENU_VIDEO'];
        this.currentMenuName = 'MENU_VIDEO';
        return;
      }
      if (
        !this.currentMenu &&
        (key == window.KeyEvent.VK_RED ||
          key == window.KeyEvent.VK_GREEN ||
          key == window.KeyEvent.VK_YELLOW ||
          key == window.KeyEvent.VK_BLUE)
      ) {
        this.currentMenu = MENUS['MAIN_MENU'];
        return;
      }
      if (!this.currentMenu) {
        return;
      }
      const item = this.currentMenu.find(m => {
        // console.log('mkey' + window.KeyEvent[m.key], 'key' + key);
        return window.KeyEvent[m.key] == key;
      });
      if (item && item.enabled(store)) {
        let itemIdx = this.currentMenu.findIndex(m => {
          // console.log('mkey' + window.KeyEvent[m.key], 'key' + key);
          return window.KeyEvent[m.key] == key;
        });

        vm.currentMenu[itemIdx].pressed = true;
        setTimeout(() => {
          try {
            vm.currentMenu[itemIdx].pressed = false;
          } catch (error) {
            console.warn(error);
          }
        }, 100);
        let event =
          typeof item.event == 'function' ? item.event(store) : item.event;
        let eventParams =
          typeof item.eventParams == 'function'
            ? item.eventParams(store)
            : item.eventParams;
        setTimeout(() => {
          eventBus.emit(event, eventParams);
        }, 200);
      }
      if (key == 'ArrowUp' || key == window.KeyEvent.VK_UP) {
        if (!this.currentMenuName || this.currentMenuName === 'MAIN_MENU') {
          eventBus.emit(EVENTS.SHOW_COOKIE_PAGE);
        }
      }
    });
    eventBus.on(EVENTS.HIDE_MENU, () => {
      vm.currentMenu = null;
      vm.currentMenuName = null;
      vm.lastClosure = new Date();
    });
    eventBus.on(EVENTS.OPEN_MENU, menu => {
      if (this.currentMenu) {
        this.currentMenu = null;
        this.currentMenuName = null;
        setTimeout(() => {
          this.currentMenu = MENUS[menu];
          this.currentMenuName = menu;
        }, 300);
      } else {
        this.currentMenu = MENUS[menu];
        this.currentMenuName = menu;
      }
    });
    setInterval(() => {
      const now = new Date();
      if (vm.currentMenu) {
        const diff = now.getTime() - vm.lastOperation.getTime();
        const interval = MENU_DURATION;
        if (diff > interval) {
          vm.currentMenu = null;
          vm.currentMenuName = null;
          vm.lastClosure = new Date();
        }
      } else if (vm.lastClosure) {
        const diff = now.getTime() - vm.lastClosure.getTime();
        if (diff > 180000) {
          eventBus.emit(EVENTS.OPEN_MENU, 'MAIN_MENU');
        }
      }
      try {
        const lastClosureTime = vm.lastClosure ? vm.lastClosure.getTime() : 0;
        const lastOperationTime = vm.lastOperation
          ? vm.lastOperation.getTime()
          : 0;
        let lastActionTime = Math.max(lastClosureTime, lastOperationTime);
        if (
          now.getTime() - lastActionTime > TITLE_ROTATION_SEC * 1000 &&
          vm.showNewsPreview
        ) {
          vm.incrementArticleIdx();
          vm.lastOperation = new Date();
        }
        if (now.getTime() - lastActionTime > TITLES_INTERVAL_SEC * 1000) {
          vm.showNewsPreview = true;
          vm.lastShowNewsPreview = new Date();
        }
        if (
          vm.showNewsPreview &&
          now.getTime() - vm.lastShowNewsPreview > TITLE_DURATION_SEC * 1000
        ) {
          vm.showNewsPreview = false;
        }
      } catch (error) {
        console.warn(error);
      }
    }, 1000);
  }
};

// const MAIN_MENU = 0;
// const MENU_CITTA = 1;
// const MENU_CURIOSITA = 2;

const MENUS = {
  MAIN_MENU: [
    {
      color: 'red',
      label: 'Like',
      event: EVENTS.LIKE,
      key: 'VK_RED',
      // enabled: (store) => store.currentContentType == 'song',
      id: 'bar-down-box-red',
      pressed: false,
      enabled: () => true
    },
    {
      color: 'green',
      label: store.currentCity ? store.currentCity.name : 'La tua città',
      event: EVENTS.OPEN_MENU,
      eventParams: store =>
        !store.currentCity ? 'MENU_CITTA_SCEGLI' : 'MENU_CITTA',
      key: 'VK_GREEN',
      pressed: false,
      enabled: () => true
    },
    {
      color: 'yellow',
      label: 'Curiosità',
      event: EVENTS.OPEN_MENU,
      eventParams: 'MENU_CURIOSITA',
      key: 'VK_YELLOW',
      pressed: false,
      enabled: store =>
        store.currentBio ||
        store.currentLyrics ||
        store.instagramContents.length
    },
    // {
    //   color: 'blue',
    //   label: 'Video',
    //   event: EVENTS.SHOW_VIDEO,
    //   key: 'VK_BLUE',
    //   pressed: false,
    //   enabled: store => store.videoclipUrl
    // }
    {
      color: 'blue',
      label: 'Video',
      event: EVENTS.OPEN_MENU,
      eventParams: 'MENU_VIDEO',
      key: 'VK_BLUE',
      pressed: false,
      enabled: () => true
    }
  ],
  MENU_CITTA: [
    {
      color: 'red',
      label: store.currentCity ? store.currentCity.name : 'Seleziona la città',
      event: EVENTS.OPEN_MENU,
      key: 'VK_RED',
      pressed: false,
      eventParams: 'MENU_CITTA_SCEGLI',
      enabled: () => true
    },
    {
      color: 'green',
      key: 'VK_GREEN',
      label: 'Meteo',
      event: EVENTS.SHOW_RIGHT_BLOCK,
      pressed: false,
      eventParams: { widget: 'meteo', color: 'green' },
      enabled: store => store.currentCity.hasMeteo
    },
    {
      color: 'yellow',
      label: 'News',
      key: 'VK_YELLOW',
      pressed: false,
      event: EVENTS.SHOW_RIGHT_BLOCK,
      eventParams: { widget: 'news', color: 'yellow' },
      enabled: store => store.currentCity.hasNews
    }
  ],
  MENU_CITTA_SCEGLI: [
    {
      color: 'red',
      label: 'Seleziona la tua città',
      type: 'cityChoiche',
      pressed: false,
      event: EVENTS.CHOICHE_CITY,
      enabled: () => true
    },
    {
      color: 'green',
      key: 'VK_GREEN',
      label: 'Meteo',
      pressed: false,
      event: EVENTS.SHOW_MENU,
      enabled: () => false
    },
    {
      color: 'yellow',
      label: 'News',
      pressed: false,
      event: EVENTS.SHOW_MENU,
      enabled: () => false
    }
  ],
  MENU_CURIOSITA: [
    {
      color: 'red',
      key: 'VK_RED',
      pressed: false,
      label: 'Artista',
      event: EVENTS.SHOW_RIGHT_BLOCK,
      eventParams: { widget: 'bio', color: 'red' },
      enabled: () => store.currentBio
    },
    {
      color: 'green',
      key: 'VK_GREEN',
      pressed: false,
      label: 'Lyrics',
      event: EVENTS.SHOW_RIGHT_BLOCK,
      eventParams: { widget: 'lyrics', color: 'green' },
      enabled: () => store.currentLyrics
    },
    {
      color: 'blue',
      key: 'VK_BLUE',
      pressed: false,
      label: 'Social',
      event: EVENTS.SHOW_RIGHT_BLOCK,
      eventParams: { widget: 'instagram', color: 'blue' },
      enabled: () => store.instagramContents.length
    }
  ],
  MENU_VIDEO: [
    {
      color: 'red',
      key: 'VK_RED',
      pressed: false,
      label: 'Videoclip onair',
      event: EVENTS.SHOW_VIDEO,
      eventParams: store => {
        let videoTitle = store.currentSong.title;
        let videoSubtitle = store.currentSong.artist;
        if (store.isMixato) {
          videoTitle = 'Sequenza Mixata';
          videoSubtitle = '';
        }
        return {
          videoclipUrl: store.videoclipUrl,
          title: videoTitle,
          subtitle: videoSubtitle
        };
      },
      enabled: store => store.videoclipUrl && !store.isPlayingVideo
    },
    {
      color: 'green',
      key: 'VK_GREEN',
      pressed: false,
      label: 'Showcase',
      event: EVENTS.SHOW_FULL_PAGE,
      eventParams: { widget: 'showcase', search: false },
      enabled: () => true
    },
    {
      color: 'yellow',
      key: 'VK_YELLOW',
      pressed: false,
      label: 'Kitchen Sound',
      event: EVENTS.SHOW_FULL_PAGE,
      eventParams: { widget: 'archivio', search: false },
      enabled: () => true
    },
    {
      color: 'blue',
      key: 'VK_BLUE',
      pressed: false,
      label: 'Top Chart',
      event: EVENTS.SHOW_FULL_PAGE,
      eventParams: { widget: 'topChart', search: false },
      enabled: () => true
    }
  ]
};
</script>
<style lang="scss" scoped>
.bottom-area,
.news-preview,
.bar-down {
  width: 100vw;
}
.bottom-area {
  height: 100px;
  bottom: 0px;
  left: 0px;
  position: fixed;
  padding-top: 10px;
  z-index: 3;
}
.news-preview {
  height: 140px;
  background: white;
  display: flex;
  margin: auto;
  margin-top: -40px;
  // width: 760px;
  padding: 0 30px;
  border: yellow solid 1px;
  // width: 830px;
  width: 790px;
}

.bar-down {
  display: flex;
  background: rgba(0, 0, 0, 0.71) none repeat scroll 0% 0%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px -3px 7px 0px;
  padding-top: 10px;
  height: 90px;
  justify-content: center;
  /deep/ {
    .pressed.bar-down-box {
      opacity: 0.5;
    }
  }
  .cookie {
    .bar-down-box-button {
      width: 23px;
      height: 23px;
      box-shadow: rgba(255, 255, 255, 0.5) 1px 1px 3px 0px;
      svg {
        width: 26px;
        height: 26px;
        /deep/ #Arrow---Up-Circle {
          fill: #ccc;
          // stroke: #ccc;
          // width: 24px;
          // height: 24px;
        }
      }
    }
  }
}
</style>

// const location = window.location;
const dashjs = window.dashjs;
let channel;
export default class VideoPlayer {
  listeners = [];
  newUrl;
  dashPlayer;
  constructor(config) {
    this.initialized = false;
    this.src = config.src;
    this.videoType = config.videoType;
    this.container = config.container;
    this.player = this.createPlayer();
  }
  createPlayer() {
    let player = document.createElement('video');
    if (this.videoType == 'application/dash+xml') {
      this.dashPlayer = dashjs.MediaPlayer().create();
      this.dashPlayer.initialize(player, this.src, false);
    } else {
      let source = document.createElement('source');
      source.src = this.src;
      source.type = this.videoType;
      player.appendChild(source);
    }
    player.id = getRandomId();
    player.autoplay = false;
    this.container.appendChild(player);
    return player;
  }

  init() {
    try {
      let broadcast = document.getElementById('broadcast');
      if (!broadcast) {
        broadcast = document.createElement('object');
        broadcast.setAttribute('type', 'video/broadcast');
        broadcast.id = 'broadcast';
        document.body.appendChild(broadcast);
      }
      console.log(broadcast);
      channel = broadcast.currentChannel;
      broadcast.bindToCurrentChannel();
      broadcast.stop();
      console.log('broadcast stopped');
      broadcast.onChannelChangeError = (ch, err) => {
        console.log('onChannelChangeError', ch, err);
        if (!ch) {
          ch = channel;
        }
        broadcast.setChannel(ch);
      };
      broadcast.onChannelChangeSucceeded = (ch, err) => {
        console.log('onChannelChangeSucceeded', ch, err);
      };
    } catch (e) {
      console.log('error stopping broadcast');
    }
  }

  play() {
    console.log('new url:', this.newUrl);
    // if (this.newUrl) {
    //   if (this.dashPlayer) {
    //     this.dashPlayer.destroy();
    //     if (this.videoType == 'application/dash+xml') {
    //       this.dashPlayer = dashjs.MediaPlayer().create();
    //       this.dashPlayer.initialize(this.player, this.newUrl, false);
    //     }
    //   } else {
    //     let source = this.player.getElementsByTagName('source');
    //     if (source.length) {
    //       source[0].src = this.newUrl;
    //       this.newUrl = null;
    //       this.player.load();
    //     }
    //   }
    // }
    // if (!this.initialized) {
    //   this.init();
    // }
    const container = this.container;
    container.classList.add('play');
    setTimeout(() => {
      container.classList.remove('play');
    }, 500);
    try {
      this.player.play();
    } catch (error) {
      console.warn('PLAY WARINIG', error);
    }
  }

  pause() {
    const container = this.container;
    container.classList.add('pause');
    setTimeout(() => {
      container.classList.remove('pause');
    }, 500);
    try {
      this.player.pause();
    } catch (error) {
      console.warn('PAUSE WARINIG', error);
    }
  }

  stop() {
    if (this.dashPlayer) {
      this.dashPlayer.pause();
      // this.dashPlayer.resetSettings();
    }
    this.player.pause();
    this.destroy();
  }

  fastForward() {
    const curTime = this.player.currentTime;
    if (curTime + 5 < this.player.duration) {
      this.player.currentTime += 5;
    }
  }
  rewind() {
    const curTime = this.player.currentTime;
    if (curTime - 5 > 0) {
      this.player.currentTime -= 5;
    }
  }
  seek(val) {
    const curTime = this.player.currentTime;
    if (curTime + val < this.player.duration && curTime + val > 0) {
      this.player.currentTime += val;
    }
  }

  setURL(url) {
    console.log('setURL', url);
    this.newUrl = url;
  }

  addListener(eventName, handler) {
    this.listeners.push({ eventName, handler });
    console.log('this.player.addEventListener', eventName);
    this.player.addEventListener(eventName, handler);
  }

  removeListeners() {
    const listeners = this.listeners;
    for (const item of listeners) {
      console.log('remove listener', item.eventName);
      this.player.removeEventListener(item.eventName, item.handler);
    }
    this.listeners = [];
  }

  destroy() {
    try {
      this.removeListeners();
      console.log('this', this);
      console.log('dashPlayer', this.dashPlayer);
      console.log('player', this.player);
      console.log('beforedestroy dashPlayer');
      if (this.dashPlayer) {
        console.log('destroy dashPlayer');
        this.dashPlayer.destroy();
        this.dashPlayer = null;
        delete this.dashPlayer;
      }
      if (this.player) {
        this.player.pause();
        this.player.src = '';
        this.player.removeAttribute('src');
        let last = this.player.lastChild;
        console.log('destroy: while');
        while (last) {
          this.player.removeChild(last);
          last = this.player.lastChild;
        }
        console.log('destroy: end while');
        console.log('load');
        this.player.load();
        console.log('removeChild');
        this.player.remove(); // clear from dom
        console.log('video null');
        this.player = null;
        delete this.player;
      }
      try {
        // window.location.reload();
      } catch (error) {
        console.warn(error);
      }
    } catch (e) {
      console.log('Error at destroy()');
      console.log(e.description);
    }
    // finally {
    //   location.reload();
    // }
  }
}

function getRandomId() {
  return 'video-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
